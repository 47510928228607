.room-col {
	width: 10%;
}

.c-1 {
	width: 6.25%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-2 {
	width: 12.5%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-3 {
	width: 18.75%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-4 {
	width: 25%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-5 {
	width: 31.25%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-6 {
	width: 37.5%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-7 {
	width: 43.75%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-8 {
	width: 50%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-9 {
	width: 56.25%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-10 {
	width: 62.5%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-11 {
	width: 68.75%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-12 {
	width: 75%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-13 {
	width: 81.25%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-14 {
	width: 87.5%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-15 {
	width: 87.5%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

.c-16 {
	width: 93.75%;
	margin-right: 0.07%;
	margin-left: 0.07%
}

/*.c-17{
	width: 100%;
	margin-right: 0.07%;
	margin-left: 0.07%
}*/

.booking-card-1 {
	border-radius: 8px;
	background-color: #c0dee5;
}

.booking-card-2 {
	border-radius: 8px;
	background-color: #cdedce;
}

.booking-card-3 {
	border-radius: 8px;
	background-color: #ffe2d1;
}

.booking-card-4 {
	border-radius: 8px;
	background-color: #faffd4;
}

.chart {
	/* border-collapse:separate; */
	/* border-spacing:2.6px 3px; */
}

.master-icon-btn {
	background-color: transparent;
	border-radius: 200px;
	height: 20px;
	width: 20px;
	border: 1.2px solid #889;
}

.flags-table thead {
	background-color: #29335c !important;
	color: #fff !important;
}

.flags-table td:hover {
	color: #FF8B00;
	cursor: pointer;
	font-weight: bold
}

.room-tile-img {
	height: 70px;
	border-radius: 8px;
	width: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	content: '';
}

.room-tile-graph {
	background-color: #FFF;
	border-radius: 200px;
	height: 20px;
	width: 20px;
	color: #444;
	border: none;
	transition: all 0.1s ease-in;
	position: relative;
	right: 0;
	left: 87%;
	top: 5px;
}

.room-tile-graph:hover {
	background-color: #FF8B00;
	color: #FFF;
}

.grey-card {
	background-color: #eef1f2;
	border-radius: 12px;
	height: 100%;
	transition: all 0.1s ease-in;
}

.grey-card:hover {
	background-color: #e3eaeb;
	cursor: pointer
}

.comp-pagination {
	position: sticky;
	bottom: 0;
	background-color: #fff;
	border-top: 4px solid #e2eaec !important;
}


.similar-match {
	border: 3px solid #1565c0 !important;
	border-style: dashed !important;
}

.exact-match {
	border: 3px solid black !important;
	border-style: dashed !important;
}

.no-match {
	opacity: 0.7;
}

.hover-underline {
	cursor: pointer;
}

.hover-underline:hover {
	text-decoration: underline;
}

.underline {
	text-decoration: underline;
}

.sticky-drawer {
	position: sticky;
	top: -1px;
	width: 100%;
	background-color: white;
	z-index: 100;
	height: 4em;
	padding: 0.5em;
	transition: opacity 0.5s ease, visibility 0.5s ease;
}

.calender-card {
	overflow-y: scroll;
	/* height: 50em; */
	/* height:100%; */
}

.master-section-card {
	/* height: calc(100% - 60px); */
	height: calc(100vh - 10vh);
	display: flex;
	flex-direction: column;
}

.master-filter-container {
	/* flex: 1 1 10%;  */
	padding: 1em;
}

.drawer-btn-container {
	/* flex: 1 1 8%; */
	margin-left: 1em;
}

.calender-card {
	flex: 1 1 82%;
	overflow-y: scroll;
}


.drawer-btn-container-close {
	/* flex: 1 1 8%; */
	margin-left: 1em;
}

.calender-card-close {
	flex: 1 1 92%;
	overflow-y: scroll;
}

.title-card {
	background-color: rgb(255, 249, 232);
	padding: 1em;
	border-radius: 10px;
	font-weight: 600;
	width: 38%;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 0.7em;
}

.card-insights-container {
	position: relative;
	/* width: 12em; */
	border: none;
	font-size: 0.9rem;
}

.extended-room-chip {
	position: absolute;
	top: -0.9em;
	left: 0.25em;
	background-color: white;
	color: black;
	padding: 0.2em 0.5em;
	border-radius: 50px;
	font-size: 0.8em;
	/* cursor: pointer; */
	width: auto;
	border: 1px solid black;
	height: 2em;
}

.base-room-chip {
	position: absolute;
	top: 7.5em;
	left: -0.5em;
	color: black;
	background-color: white;
	padding: 0.2em 0.5em;
	border-radius: 50px;
	font-size: 0.85em;
	/* cursor: pointer; */
	width: auto;
	border: 1px solid black;
	left: 3.8em;
	transform: translateX(-50%);
	height: 2em;
	text-align: center;
	font-weight: bold;
}

.card-body-insights {

	background-color: #d8d8d8;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	color: black;
	font-weight: 600;
	padding: 0.6em;
	border-radius: 5px;

}

.card-body-insights-base {
	/* background:rgb(235, 245, 250); */
	background-color: rgb(255, 249, 232);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	color: black;
	font-weight: 600;
	padding: 0em 0.5em;
	border-radius: 5px;

}

.card-img-top {
	width: 100%;
	height: 100%;
	object-fit: cover;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img-container {
	position: relative;
	height: 7em;
	width: 100%;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.card-item {
	display: flex;
	justify-content: space-between;
	/* margin-top: 0.2em; */
}

#roomCarousel {
	height: 17em;
}


.circle-indicator {
	width: 10px !important;
	height: 10px !important;
	border-radius: 50% !important;
	background-color: gray !important;
	display: inline-block !important;
	margin: 0 5px !important;
}


.circle-indicator.active {
	background-color: black;
	/* Color when active */
	top: -3em;
}

.circle-indicator:hover {
	cursor: pointer;
	/* Show a pointer cursor on hover */
	top: -3em;
}

.fs-8 {
	font-size: 0.60em;
}

.carousel-control-prev {
	left: -4em;
}

.carousel-control-next {
	right: -4em;
}

/* .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="0 0 8 8"%3E%3Cpath d="M5.707 1.293a1 1 0 0 0-1.414 0L1.293 4.707a1 1 0 0 0 0 1.414l2.999 2.999a1 1 0 0 0 1.414-1.414L3.414 5l2.293-2.293a1 1 0 0 0 0-1.414z"/%3E%3C/svg%3E');
}
.carousel-control-next-icon {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="0 0 8 8"%3E%3Cpath d="M2.293 1.293a1 1 0 0 1 1.414 0L6.707 4.707a1 1 0 0 1 0 1.414L4.121 8.121a1 1 0 0 1-1.414-1.414L5.586 5 3.293 2.707a1 1 0 0 1 0-1.414z"/%3E%3C/svg%3E');
} */

.glide__arrows {
	position: absolute;
	top: 35%;
	width: 100%;
	transform: translateY(-50%);
	display: flex;
	justify-content: space-between;
	pointer-events: none;
}

.glide__arrow {

	color: white;
	border: none;
	padding: 0.5em;
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
	/* This ensures clicks on the arrows work */
}

.glide__arrow--left {
	position: absolute;
	left: 10px;
}

.glide__arrow--right {
	position: absolute;
	right: 10px;
}

.glide__slide {
	margin-top: 1em;
}

/* Style for the Glide.js pagination indicators */
.glide__bullets {
	position: absolute;
	bottom: 0px;
	/* Adjust the position from the bottom */
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 5px;
	/* Adjust the space between indicators */
	pointer-events: none;
	/* This allows clicks to pass through */
}

.glide__bullet {
	background: rgba(0, 0, 0, 0.5);
	border: none;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
}

.glide__bullet.active {
	background: black;
}

.glide__slide {
    transition: transform 0.3s ease-in-out;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
}

.glide__arrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}



/* 
.master-left-arrow{
	position:fixed;
	top: 40%;
	z-index: 10;
	cursor: pointer;
}
.master-right-arrow{
	position:fixed;
	top: 40%;
	right: 2em;
	z-index: 10;
	cursor: pointer;
} */
.carousel-legend {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	padding: 10px;
	/* background-color: #f9f9f9; */
	/* border-top: 1px solid #ddd; */
}

.legend-item {
	display: flex;
	align-items: center;
	margin-right: 20px;
	font-size: 0.8em;
	color: black;
}

.legend-color {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin-right: 8px;
}

.overselling {
	background-color: rgb(255, 4, 4);
	border-radius: 50px;
}

.underselling {
	border-radius: 50px;
	background-color: rgb(255, 184, 1);
}

.focus-comp-table-container .MuiDataGrid-columnHeaders {
	max-height: 8em !important;
	height: 8em !important;
	font-weight: bold;
	line-height: 2em !important;
}

.focus-comp-table-container .MuiDataGrid-columnHeader {
	height: 100% !important;
}

.even-row {
	background-color: rgb(249 248 248);

}

.odd-row {
	background-color: rgb(234 234 232);
}

.cal-popup-title {
	width: 98%;
}

.drawer-filter {
	width: 40%;
}

.flag-btn {
	color: black;
	font-weight: bold;
	border-radius: 4px;
	background-color: black;
	width: 2.8em;
	font-size: 0.9em;
	padding: 0 1em;
	height: 1.5em;
}

#tags-outlined {
	display: none;
}

.grayscale-div {
	filter: grayscale(100%);
}

.non-grayscale-div {
	filter: none;
	position: absolute;
	right: 3px;
	bottom: 0px;
	isolation: isolate;
}


.track-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slides {
    display: flex;
    transition: transform 0.4s ease-in-out;
    list-style: none;
    padding: 0;
    margin: 0;
}

.slide {
    flex: 0 0 auto;
    transition: opacity 0.3s ease-in-out;
}

.card-insights-container {
    transition: all 0.3s ease-in-out;
}

.slider-arrows {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.slider-arrow-left,
.slider-arrow-right {
    background: none;
    border: none;
    cursor: pointer;
    padding: -6px;
}

.slider-arrow-left:disabled,
.slider-arrow-right:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.slider-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: gray;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.dot.active {
    background-color: black;
}

.custom-dot-list-style{
	margin-top: 1em;
}
.react-multi-carousel-list {
	padding: 0.5rem 0 2rem 0;
}
.multimodal-dynamic{
	justify-content: center;
	/* width: 100%; */
}